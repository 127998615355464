import * as React from "react";
import { graphql } from "gatsby";
import type { HeadFC } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import useCookieState from "../hooks/useCookieState";
import usePageData from "../hooks/usePageData";
import { PropsType } from "../hooks/propsType";
import Seo from "../components/SEO";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Reviews from "../components/Reviews";
import { transformedData, getRandomDateInLast15Days } from "../utils/functions";
import "../assets/scss/index.scss";

const Page: React.FC<PropsType> = ({ data, pageContext }) => {
  const [compareList, setCompareList] = useCookieState(
    "compare",
    JSON.stringify([])
  );
  const [openModal, setOpenModal] = React.useState(false);

  const { settings, products, productsMeta, curentPage } = usePageData(
    data,
    pageContext
  );

  return (
    <div className="tf">
      <Header
        settings={transformedData(settings?.header)}
        settingsFooter={transformedData(settings?.footer)}
        products={products}
        compareList={compareList}
        setCompareList={setCompareList}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <Hero
        settings={transformedData(curentPage?.metadata)}
        descriptionShort={false}
      />
      <Reviews
        settings={transformedData(settings?.reviews)}
        products={products}
      />
      <Footer settings={transformedData(settings?.footer)} />
    </div>
  );
};

export const Head: HeadFC<PropsType> = ({ data, pageContext }) => {
  const { curentPage, settings } = usePageData(data, pageContext);
  const metaData = transformedData(curentPage?.metadata);
  const image = getImage(
    transformedData(settings?.metadata).images[0]?.heroImage
  );
  return (
    <Seo
      title={metaData?.seoTitle}
      description={metaData?.seoDescription}
      image={image?.images?.fallback?.src}
      url={"/" + curentPage?.slug + "/"}
      schema={JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: process.env.NAME,
        url: process.env.PUBLIC_URL + "/" + curentPage?.slug + "/",
        image: process.env.PUBLIC_URL + image?.images?.fallback?.src,
        headline: metaData?.seoTitle,
        datePublished:
          new Date(curentPage?.date).toISOString().slice(0, 19) + "+08:00",
        dateModified: getRandomDateInLast15Days(),
      })}
    />
  );
};

export const query = graphql`
  query {
    allMdx(sort: { frontmatter: { date: DESC } }) {
      nodes {
        ...MdxFields
      }
    }
  }
`;

export default Page;
