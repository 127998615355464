import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import "./index.scss";

interface HeroProps {
  settings: any;
  descriptionShort?: boolean;
  short?: boolean;
}

const Hero: React.FC<HeroProps> = ({
  settings,
  descriptionShort = false,
  short = true,
}) => {
  const image = settings?.images
    ? getImage(settings?.images[0]?.heroImage)
    : false;
  return (
    <section className={short ? "hero short" : "hero"}>
      <div className={image ? "container bg-image" : "container"}>
        <h1>{settings?.title}</h1>
        <p className={descriptionShort ? "short" : ""}>{settings?.titleSub}</p>
        {settings?.heroButton && (
          <div className="hero-action">
            <Link to={settings?.heroLink}>
              <button type="button" className="orangeButton">
                {settings?.heroButton}
              </button>
            </Link>
          </div>
        )}
        {settings?.searchPlaceholder && (
          <div className="hero-search">
            <div className="hero-search-data">
              <input type="text" placeholder={settings?.searchPlaceholder} />
              <SearchOutlinedIcon />
            </div>
          </div>
        )}
      </div>
      {image && <div className="bg-cover"></div>}
      {image && (
        <GatsbyImage image={image} alt="" className="background-hero" />
      )}
    </section>
  );
};

export default Hero;
