import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./index.scss";

interface ReviewsProps {
  settings?: any;
  products?: any;
}

const Reviews: React.FC<ReviewsProps> = ({ settings, products }) => {
  const reviewsList = products.reduce(
    (acc: any, obj: any) => acc.concat(obj.reviews),
    []
  );
  return (
    <section className="reviews-all light">
      <div className="container">
        <h3>{settings?.subTitle}</h3>
        <h2>{settings?.title}</h2>
        <p className="reviews-desc">{settings?.content}</p>
        <div className="reviews-list">
          {reviewsList?.map((e: any, i: any) => {
            const logoReview = getImage(e?.heroImage);
            return (
              <div className="reviews-item" key={i}>
                <div className="reviews-item-head">
                  <GatsbyImage image={logoReview} alt={e?.alt} />
                </div>
                <p>{e?.content}</p>
                <div className="reviews-link">
                  <nav>
                    <ul>
                      <li>
                        <a href={e?.slug} target="_blank" rel="nofollow">
                          {settings?.readMore} <ArrowRightAltIcon />
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Reviews;
